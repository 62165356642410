<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card v-if="showAddCard" tile class="row" style="width: 100%">
          <v-card-title class="py-1 headline default--card-title white--text">
            <v-row>
              <v-col
                cols="12"
                class="px-0 d-flex align-left justify-space-between"
              >
                <span
                  class="px-1 py-2 ml-2 text-left"
                  style="color: black; font-size: 16px !important"
                >
                  Please complete your registration to continue storage
                </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row class="align-left">
              <span class="px-1 py-2 ml-2 text-left" style="color: black">
                By continuing, I allow Prelude Cryopreservation Services, LLC to
                store my card and payment method for future use.
              </span>
            </v-row>
            <v-row class="align-left">
              <span class="px-1 py-2 ml-2 text-left" style="color: black">
                <strong class="black--text">Cryo Storage Refund Policy:</strong>
                All storage payments are non-refundable once processed.
              </span>
            </v-row>
            <v-row class="align-left">
              <span class="px-1 py-2 ml-2 text-left" style="color: black">
                If you would like to discontinue your storage service, please
                contact the Cryo Services Team at
                cryoservices@preludefertility.com or 888-216-6538
              </span>
            </v-row>
            <v-row class="align-left">
              <span class="px-1 py-2 ml-2 text-left" style="color: black">
                <strong class="black--text">
                  Pay by Credit: Add a new payment method to your account
                </strong>
              </span>
            </v-row>
            <v-row class="py-2 flex align-center justify-center text-center">
              <v-card
                v-if="!checkMobile"
                light
                width="70%"
                clas="justify-center"
                style="background-color: #d4d4d4"
                rounded
                raised
              >
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Card Number"
                          v-model="account"
                          :rules="[
                            ...inputRule,
                            ...accountRule,
                            ...onlyNumsRule
                          ]"
                          validate-on-blur
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Card CVV"
                          v-model="cvv"
                          :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
                          validate-on-blur
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="pt-0">
                        <v-select
                          class="mt-0"
                          label="Month"
                          :items="months"
                          v-model="selectedMonth"
                          :rules="inputRule"
                          menu-props="auto"
                        ></v-select>
                      </v-col>
                      <v-col class="pt-0">
                        <v-select
                          class="mt-0"
                          label="Year"
                          menu-props="auto"
                          :items="years"
                          v-model="selectedYear"
                          :rules="inputRule"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-text-field
                      label="Zip/Postal Code"
                      v-model="zipcode"
                      :rules="[...inputRule, ...onlyNumsRule]"
                      validate-on-blur
                    />
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card
                v-else
                light
                width="100%"
                clas="justify-center"
                style="background-color: #d4d4d4; margin-left: 25px"
                rounded
                raised
              >
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Card Number"
                          v-model="account"
                          :rules="[
                            ...inputRule,
                            ...accountRule,
                            ...onlyNumsRule
                          ]"
                          validate-on-blur
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Card CVV"
                          v-model="cvv"
                          :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
                          validate-on-blur
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          class="mt-0"
                          label="Month"
                          :items="months"
                          v-model="selectedMonth"
                          :rules="inputRule"
                          menu-props="auto"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          class="mt-0"
                          label="Year"
                          menu-props="auto"
                          :items="years"
                          v-model="selectedYear"
                          :rules="inputRule"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Zip/Postal Code"
                          v-model="zipcode"
                          :rules="[...inputRule, ...onlyNumsRule]"
                          validate-on-blur
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-row>

            <v-row class="py-2 flex align-center justify-center text-center">
              <v-btn
                small
                rounded
                :style="styleCustom"
                class="ml-3 text-center bottom-fixed"
                color="primary"
                @click="addNewCard"
                :loading="submitting"
                dark
              >
                Continue
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { api, auth } from '../../sharedPlugin';
import device from '../../utils/device';
const Swal = require('sweetalert2');

export default {
  props: ['showAddCard'],
  data() {
    return {
      snackbar: false,
      months: [],
      cards: [],
      years: [],
      submitting: false,
      styleCustom:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '2vw',
              'margin-top': '10px',
              width: '200px'
            }
          : {
              height: '35px',
              'font-size': '0.9vw',
              'margin-top': '10px',
              width: '200px'
            },

      card: {
        type: Object,
        default: function () {
          return {
            profileid: 0,
            account: '',
            cvv: '',
            expiry: '',
            zipcode: '',
            is_default: 0
          };
        }
      },
      valid: false,
      inputRule: [(v) => !!v || 'Input Required'],
      accountRule: [
        (v) =>
          (v && (v.length == 15 || v.length == 16)) ||
          'Card number should be 15-16 characters long'
      ],
      decimalRule: [
        (v) =>
          !isNaN(Number.parseFloat(v)) || 'Input must be a valid decimal value'
      ],
      cvvRule: [
        (v) =>
          (v && (v.length == 3 || v.length == 4)) || 'Must be 3 or 4 characters'
      ],
      onlyNumsRule: [(v) => (v && /^\d+$/.test(v)) || 'Only numbers allowed'],
      style_custom: {
        'margin-left': '10px'
      }
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    checkMobile() {
      return device() === 'mobile' ? true : false;
    },
    selectedMonth: {
      get() {
        const month =
          this.card.expiry && this.card.expiry.length > 0
            ? this.card.expiry.substring(0, 2)
            : '';
        return month !== '' ? month : '';
      },
      set(val) {
        this.card.expiry = `${val}${this.formattedYear(this.selectedYear)}`;
      }
    },
    selectedYear: {
      get() {
        const currentYear = new Date().getFullYear().toString();
        const year =
          this.card.expiry && this.card.expiry.length > 0
            ? currentYear.substring(0, 2) + this.card.expiry.substring(2)
            : 0;
        const yearIndex = this.years.findIndex((y) => y === year);

        return this.card.expiry === '' || yearIndex < 0
          ? ''
          : this.years[yearIndex];
      },
      set(val) {
        this.card.expiry += `${this.selectedMonth}${this.formattedYear(val)}`;
      }
    },
    account: {
      get() {
        return this.card.account;
      },
      set(val) {
        this.card.account = val;
      }
    },
    cvv: {
      get() {
        return this.card.cvv;
      },
      set(val) {
        this.card.cvv = val;
      }
    },
    expiry: {
      get() {
        return this.card.expiry;
      },
      set(val) {
        this.card.expiry = val;
      }
    },
    zipcode: {
      get() {
        return this.card.zipcode;
      },
      set(val) {
        this.card.zipcode = val;
      }
    }
  },
  methods: {
    formattedYear(year) {
      return year.substring(2);
    },
    addNewCard() {
      if (this.$refs.form.validate()) {
        let isDefault = this.cards && this.cards.length > 0 ? 0 : 1;
        this.card.is_default = isDefault;
        this.submitting = true;

        let isCard =
          this.cards.filter(
            (card) => card.last_four === this.card.account.slice(-4)
          ).length > 0
            ? true
            : false;

        if (isCard) {
          this.submitting = false;

          this.showSnackbar({
            message: 'This card is already registered!',
            color: 'error'
          });
          return;
        }

        this.submitting = false;
        return api.CardConnect.addCard(this.card)
          .then((res) => {
            this.showAddCard = false;
            Swal.fire({
              title: 'Payment Method Update Successful!',
              text: 'Your credit card will be charged for upcoming storage payments. If you have any outstanding balances, please review your account.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ok'
            }).then((result) => {
              this.$emit('sucessAddCard');
            });
          })
          .catch((e) => {
            console.log(e);
            Swal.fire({
              title: 'Payment Method Update Successful!',
              text: 'Your credit card will be charged for upcoming storage payments. If you have any outstanding balances, please review your account.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ok'
            }).then((result) => {
              this.$emit('sucessAddCard');
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        this.showSnackbar({
          message: 'Check your credit card details!',
          color: 'error'
        });
      }
    }
  },
  created() {
    for (let i = 1; i < 13; i++) {
      if (i >= 10) {
        this.months.push(`${i}`);
      } else {
        this.months.push(`0${i}`);
      }
    }

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i < currentYear + 20; i++) {
      this.years.push(`${i}`);
    }
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 30px !important;
  font-size: 0.8rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
.v-data-table.row-height-50 td {
  height: 18px !important;
}
</style>
